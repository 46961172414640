* {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  /* Modal Button */
  .modalButton {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: 12px 24px;
  }
  
   /* Popup Style */
  
  .modalContainer {
    Z-index: 10000;
    padding: 6px;
    background-color: #ffffff;
    box-shadow: 0 0 18px 0 rgb(22 23 33);
    border-radius: 8px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

  }


  .modalRight {
    width: 100%;
  }



  .content {
    font-family: sans-serif ;
    border-radius: 3px;
    flex-direction: column;
    justify-content: space-around;
    font-size: medium;
    text-align: center;
    margin-top: 1rem;
    padding: 0 2rem;
  }
  
  .btnContainer {
    padding: 1rem 1rem;
    text-align: center;
  }
  .btnContainer button {
    margin: .5rem;
    border: 1px solid #2C7A7B; 
    font-size: small;   
    font-family: sans-serif ;

  }
  .hereLink {
    text-decoration: none;
    font-weight: bold;
  }
  
  .btnPrimary {
    
    background-color:#2C7A7B;
    color: white;
    font-family: sans-serif ;

  }
  
  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #2C7A7B;
    font-family: sans-serif ;
  }
  
  .bold {
    font-weight: 600;
  }
  
  @media screen and (max-width: 300px) {
    .modalContainer {
      flex-direction: column;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100vh;
    } 
    
  
    .heading {
      margin: 1rem;
    }
  }
  
  
  