body {
  margin: 0;
  background: #000;
}
.mapLegend {
  transform: translate(312,200);
  position: absolute ;
  right: 0 !important;
  bottom: 0 !important;
  padding: 4px;
  padding-top: 0;
  background: white
}
.mapLegend div {
  opacity: 0.75 !important;
  transform: translate(0,0);
  cursor: pointer;
}
.mapLegend div .circle  {
  pointer-events: none;
  padding-right: 6px;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block
}
.mapLegend div:hover .circle {
  height: 15px;
  width: 15px;
}
.positionsIndicator {
  transform: translate(312,200);
  position: absolute ;
  left: 0 !important;
  bottom: 0 !important;
  padding: 4px;
  padding-top: 0;
  background: white
}
.positionsIndicator div {
  opacity: 2.75 !important;
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.positionsIndicator div .text {
  font-family: sans-serif ;
  font-size:11px;
  color: rgb(22, 23, 33);
  pointer-events: none ;
  user-select: none ;
  text-anchor:start ;
  display: inline ;
  margin-left: 6px
}
.positionsIndicator div .circle  {
  padding-right: 6px;
  height: 15px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  background-color: #edd4ab;
  border: 2px solid #f2b95c;
}
.positionsIndicator div .triangle {

  border: 8px solid transparent;
  border-bottom: 16px solid #edd4ab;
  margin-bottom: 8px;
}

.graphContainer {
  height: 300px;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.secondGraph {
  height: 300px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.thirdGraph {
  height: 300px;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.mapLegend .legendItemClicked .circle {
  background-color: #EBEEF2 !important;
}
.mapLegend div .text {
  font-family: sans-serif ;
  font-size:11px;
  color: rgb(22, 23, 33);
  pointer-events: none ;
  user-select: none ;
  text-anchor:start ;
  display: inline ;
  margin-left: 6px
}
.mapLegend div:hover .text {
  margin-left: 7px
}
#map {
  width: 100vw;
  height: 100vh;
}

.innerText {
  margin: 0 !important;
}

.mapboxgl-popup-content {
  width: 150px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  background-color: #00000000;
  text-align: center;
  color: white;
  padding: 5px 5px 5px;
}


.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: transparent;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: transparent;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #fd003a;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #fd003a;
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}
