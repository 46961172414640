.flatpickr-input {
  width: 100%;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  height: 40px;
  border-radius: 0.35rem;
  border: 1px solid;
  border-color: #e2e8f0;
}

.flatpickr-mobile {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  height: 40px;
  border-radius: 0.35rem;
  border: 1px solid;
  border-color: #e2e8f0;
  background-color: white;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .flatpickr-input {
    -webkit-appearance: none; /* your custom styles here */
  }
}
